import React, {useContext} from 'react';
import {
    Nav, 
    NavLink, 
    Logo,
    Bars, 
    NavMenu, 
    NavBtn,
    NavBtnLink} 
from './navbarstyle';
import { AppContext } from '../components/AppContext';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Navbar = () =>{   

    const {isAuth, setIsAuth} = useContext(AppContext); 
    const {setName} = useContext(AppContext);
    const {setState} = useContext(AppContext); 
    const nav = useNavigate();

    //Logout Function
    const handleLogout= async (e) =>{
        await axios.post("/api/logout", { withCredentials: true })
        .then(res =>{
            if(res.data === "User logged out."){
                nav("/");
                setIsAuth(false);
                setName("");
                setState("");
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return(      
        <>
            <Nav>
                <Logo to = "/">
                    Rural School Food Alliance
                </Logo>

                <Bars />

                <NavMenu>

                    <NavLink to = "/">
                        Home
                    </NavLink>

                    <NavLink to = "/about">
                        About
                    </NavLink>

                    <NavLink to = "/resources">
                        Resources
                    </NavLink>

                    <NavLink to = "/contact">
                        Contact
                    </NavLink>
                </NavMenu>

                {isAuth === false && <NavBtn>
                    <NavBtnLink to = "/signup">
                        Signup
                    </NavBtnLink>

                    <NavBtnLink to = "/login">
                        Login
                    </NavBtnLink>
                </NavBtn>}

                {isAuth === true && <NavBtn onClick={e => handleLogout()}>
                        Logout
                </NavBtn>}
            </Nav>
        </>
    );
};
export default Navbar;