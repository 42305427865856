import React, {useRef, useState,} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const Contact = () =>{   


    const [showError, setShowError] = useState(false);
    const [sendError, setSendError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    //useEffect(() => {

    //}, []);

    const captchaRef = useRef(null)

     const handleSubmit = async (e) =>{
        e.preventDefault();
        const token = captchaRef.current.getValue();



        if(token === ""){
            setShowError(true)
        }

        else{
            captchaRef.current.reset();

            await axios.post("/api/message", {token, fname, lname, email, subject, message})
            .then(res =>  
                setShowSuccess(true),
                setFname(""),
                setLname(""),
                setSubject(""),
                setEmail(""),
                setMessage(""),
            )
            .catch((error) => {
                setSendError(true);
            })
        }
    }

     return(   
        <div>
            <Navbar/>   
                <div className = "contactBackground">    
                
                    <div className = "contactContent">

                        {showError === true && <Alert className = "Alert" variant="danger" onClose={e => setShowError(false)} dismissible>
                            Please fill out reCAPTCHA before submitting.
                        </Alert>}

                        {sendError === true && <Alert className = "Alert" variant="danger" onClose={e => setSendError(false)} dismissible>
                            Error sending message.
                        </Alert>}

                        {showSuccess === true && <Alert className = "Alert" variant="success" onClose={e => setShowSuccess(false)} dismissible>
                            Message sent.
                        </Alert>}

                        <h1 className = "contactTitle">Contact Us</h1>

                        <form onSubmit={handleSubmit}>
                            <p className = "contactText">
                                Thank you for your interest in the Rural School Food Alliance. Please complete the form below to contact us.
                            </p>
                            <div className = "field1">
                                <input placeholder="First Name" value = {fname} required onChange={e => setFname(e.target.value)}/>        
                                <input placeholder="Last Name" value = {lname} required onChange={e => setLname(e.target.value)}/>
                                <input type="email" placeholder="E-mail Address" value = {email} required onChange={e => setEmail(e.target.value)}/>
                                <input placeholder="Subject" value = {subject} required onChange={e => setSubject(e.target.value)}/>
                                <textarea placeholder="Message" value = {message} required onChange={e => setMessage(e.target.value)}/>
                            </div>

                        <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                ref={captchaRef}
                            />
                            <button type = "submit" className = "submitBtn">Send Message</button>
                        </form>
                    </div>            
                </div>
            <Footer/>
        </div>
    );
};
export default Contact;