import React, {useContext, useState, useEffect} from 'react';
import BuyAmerIMG from '../images/Buy American.png';
import ProStandardsIMG from '../images/Pro Standards Training.png';
import ShareTableIMG from '../images/Share Table SOP.png';
import WellnessPolicy from '../images/Wellness Policy.png';
import ModelWellness from '../images/Model Wellness Program.png';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { AppContext } from '../components/AppContext';
import axios from 'axios';
import {FaTimes} from 'react-icons/fa'

const Resources = () =>{   

    const {isAuth} = useContext(AppContext); 
    const [popUp, setPopUp] = useState(false);
    const [state, setState] = useState("");
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        //Download file function
        const handleDownloadClick = async () =>{
            //If use is authorize
            if(isAuth === true){

                const confirmBox = window.confirm(
                    "Do you really want to download " + fileName + "?"
                )
                if (confirmBox === true) {
                    //Doublecheck authorization and download
                    await axios.post("/api/fileDownload", {fileName: fileName}, { withCredentials: true })
                    .then(res =>{
                        if(res.data.message === "Okay"){
                            fetch(res.data.url, {
                                method: 'GET',
                            })
                            .then((response) => response.blob())
                            .then((blob) => {
                                // Create blob link to download
                                const url = window.URL.createObjectURL(
                                new Blob([blob]),
                                );
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute(
                                'download',
                                fileName,
                                );

                                // Append to html link element page
                                document.body.appendChild(link);

                                // Start download
                                link.click();

                                // Clean up and remove the link
                                link.parentNode.removeChild(link);
                                setFileName("");
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("We have an error: " + error);
                    })
                }
            }

            else{
                setPopUp(true);
            }
        }

        if(fileName.length > 0){
            handleDownloadClick().catch(console.error);
        }
      }, [fileName, isAuth]);

    

    //Download file function
    const handleDownloadForm = async (e) =>{

        //Doublecheck authorization and download
        await axios.post("/api/fileDownloadForm", {fileName: fileName, state: state})
        .then(res =>{
            if(res.data.message === "Okay"){
                fetch(res.data.url, {
                    method: 'GET',
                })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                    new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                    'download',
                    fileName,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setFileName("");
                    setState("");
                    setPopUp(false);
                });
            }
        })
        .catch((error) => {
            console.log("We have an error: " + error);
        })
    }

    return(  
        <div>
            <Navbar/>    
                <div className = "resourceBackground">    
                    <p className = "resourceText">Resource Center</p>

                    {(popUp === true) && <div className = "resourceForm">
                        <FaTimes className = "x" onClick={e => {setPopUp(false)}}/>
                    
                        <div>
                            <p>Please answer the question below to gain access to the resource selected:</p>
                            <input value = {fileName} required readOnly/>
                            <p>Select the state that your organization is located in.</p>
                            <select onChange={e => setState(e.target.value)}>
                                <option defaultValue="" disabled>Select your option</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>       
                        
                            <div>
                                <button type = "submit" className = "submitBtn" onClick={e => handleDownloadForm()}>Download Resource</button>
                            </div>
                        </div>
                    </div>}
                
                    <div className = "resourceProduct" onClick={e => {setFileName("Buy American Blank.pdf")}}>
                        <img className = "resourceIMG" src={BuyAmerIMG} alt="Buy American Blank.pdf"/>
                        <p className = "resourceTitle">Buy American Blank</p>
                    </div>

                    <div className = "resourceProduct" onClick={e => {setFileName("Professional Standards Tracker Tool.xlsx")}}>
                        <img className = "resourceIMG" src={ProStandardsIMG} alt="Professional Standards Tracker Tool.xlsx"/>
                        <p className = "resourceTitle">Professional Standards Tracker Tool</p>
                    </div>

                    <div className = "resourceProduct" onClick={e => {setFileName("Share Table Procedures (SOP).docx")}}>
                        <img className = "resourceIMG" src={ShareTableIMG} alt="Share Table Procedures (SOP).docx"/>
                        <p className = "resourceTitle">Share Table Procedures (SOP)</p>
                    </div>

                    <div className = "resourceProduct" onClick={e => {setFileName("Wellness POLICY.pdf")}}>
                        <img className = "resourceIMG" src={WellnessPolicy} alt="Wellness Policy.pdf"/>
                        <p className = "resourceTitle">Wellness Policy</p>
                    </div>

                    <div className = "resourceProduct" onClick={e => {setFileName("Model-Wellness-Program.docx")}}>
                        <img className = "resourceIMG" src={ModelWellness} alt="Model-Wellness-Program.docx"/>
                        <p className = "resourceTitle">Model Wellness Program</p>
                    </div>
                </div>
            <Footer/>
        </div>
    );
};
export default Resources;
