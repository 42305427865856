import React, {/*useEffect, useContext*/} from 'react';
import '../App.css';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const AboutUs = () =>{   

    //useEffect(() => {

    //}, []);

    return(  
        <div>
            <Navbar/>    
                <div className = "aboutBackground">
                    <div className = "aboutContent">
                        <h1 className = "aboutTitle">About Us</h1>
                        <p className = "aboutText">
                        The Rural School Food Alliance was created in 2022 to ensure that small and rural School Food Authorities
                        (SFAs) have the tools and resources they need to operate and administer their Child Nutrition Programs. It is our vision <i>to
                        support and promote sustainable equity for small and rural Child Nutrition Programs through advocacy, resource development,
                        and the sharing of best practices for the benefit of the Alliance.</i>
                        </p>
                        <p className = "aboutText">
                            Check back often as we update resources and share best practices. And please let us know what resources would benefit your programs! We’ll research solutions and post responses to share.
                        </p>
                    </div>
                </div>
            <Footer/>
        </div>
    );
};
export default AboutUs;
