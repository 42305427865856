import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import '../App.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const EmailVerification = () =>{   

    let { email } = useParams();
    let { token } = useParams();
    const [validToken, setValidToken] = useState(false);

    function emailToken(email, token) {
        const emailAndToken = {
            email: email,
            token: token,
        }

        axios.post("/api/verifyEmail", emailAndToken)
        .then(res => {
            if(res.data === 'Success'){
                setValidToken(true);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        emailToken(email, token);
    }, [email, token]);

     return(      
        <div>
            {validToken === true && <div> 
                Email has been verified you can now sign in.
                <NavLink to = "/">Login</NavLink>
            </div>}

        </div>
    );
};
export default EmailVerification;