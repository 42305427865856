import React, {/*useEffect, useContext*/} from 'react';
import '../App.css';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const HomePage = () =>{   

    //useEffect(() => {

    //}, []);

    return(      
        <div>
            <Navbar/>
            <div className = "homeBackground">
                <div className = "homeTitle">
                    <h1 className = "homeTitleText">Welcome to the Rural School Food Alliance</h1>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
export default HomePage;