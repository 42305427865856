import React, {useState, useContext, useEffect} from 'react';
import '../App.css';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { useNavigate } from "react-router-dom";
import { AppContext } from '../components/AppContext';

const LoginPage = () =>{   

    const {isAuth, setIsAuth} = useContext(AppContext);
    const {setState} = useContext(AppContext); 
    const {setName} = useContext(AppContext); 
    const [sendError, setSendError] = useState(false);
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const nav = useNavigate();
    
    const handleSubmit = async (e) =>{
        e.preventDefault();
        const login = {
            email: email,
            pass: pass,
        }

        await axios.post("/api/login", login)
        .then(res =>{

            if(res.data.message === "Success"){
                nav("/");
                setIsAuth(true);
                setName();
                setState();
                setEmail("");
                setPass("");
            }
            else{
                setSendError(true);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        if(isAuth === true){
            nav("/");
        }
    }, [isAuth, nav]);

    return( 
        
        <div>
            <Navbar/>
                <div className = "contactBackground">    
                
                    <div className = "contactContent">

                        {sendError === true && <Alert className = "Alert" variant="danger" onClose={e => setSendError(false)} dismissible>
                            Error invalid login.
                        </Alert>}

                        <h1 className = "contactTitle">User Login</h1>

                        <form onSubmit={handleSubmit}>
                            <div className = "field1">
                                <input type="email" placeholder="E-mail Address" value = {email} required onChange={e => setEmail(e.target.value)}/>
                                <input type="password" placeholder="Password" value = {pass} required onChange={e => setPass(e.target.value)}/>
                            </div>
                            <button type = "submit" className = "submitBtn">Submit</button>
                        </form>
                    </div>            
                </div>
            <Footer/>
        </div>
    );
};
export default LoginPage;