import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AppContext } from './components/AppContext';
import Home from './pages/home'
import About from './pages/about'
import Resources from './pages/resources'
import Contact from './pages/contact';
import Privacy from './pages/privacypolicy';
import LoginPage from './pages/login';
import SignUpPage from './pages/signup';
import EmailVerification from './components/emailValidation';
import axios from 'axios';

function App() {  

  const [isAuth, setIsAuth] = useState("");
  const [name, setName] = useState(false);

    useEffect(() => {
      axios
      .post("/loginCheck", { withCredentials: true })
      .then((res) => {
          setIsAuth(res.data.auth);
          setName(res.data.name);
      })
      .catch((err) => {
        console.error(err);
        setIsAuth(false);
    });
    }, []);
  
    return (   
      <AppContext.Provider value = {{isAuth, setIsAuth, name, setName}}>
          <Router>
            {(isAuth === true || isAuth === false) && <Routes>

              <Route path="/verify-email/:email/:token" element = {<EmailVerification/>}/>
              <Route path="/" exact element={<Home />} />
              <Route path="/about" exact element={<About />} />
              <Route path="/resources" exact element={<Resources />} />
              <Route path="/contact" exact element={<Contact />} />
              <Route path="/privacy" exact element={<Privacy />} />
              <Route path="/login" exact element={<LoginPage />} />
              <Route path="/signup" exact element={<SignUpPage />} />
              <Route path="/login" exact element={<LoginPage />} />
              
            </Routes>}
        </Router>
      </AppContext.Provider>
    );
  }
  
  export default App;