import React, {/*useEffect, useContext*/} from 'react';
import { NavLink } from 'react-router-dom';
import './footerstyle.css';

const Footer = () =>{   

    //useEffect(() => {

    //}, []);

    return(    
        <div className='footer'>
            <div className = 'linkDiv'>
                <p className='quickLinks'>Quick Links</p>

                <a href="https://theicn.org/" className='footerText'>Institute of Child Nutrition</a>
                <a href="https://www.fns.usda.gov/" className='footerText'>USDA</a>
                <a href="https://schoolnutrition.org/" className='footerText'>School Nutrition Association</a>
                <a href="https://www.fns.usda.gov/team-nutrition" className='footerText'>USDA Team Nutrition</a>
            </div>

            <div class="verticalLine">
            </div>

            <div class="footerPrivacy">
                <NavLink to = "/privacy" className='footerText'>Privacy Policy</NavLink>
            </div>
        </div>
    );
};
export default Footer;