import React, {useState, useEffect, useContext} from 'react';
import '../App.css';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/navbar';
import Footer from '../components/footer'
import { useNavigate } from "react-router-dom";
import { AppContext } from '../components/AppContext';

const SignUpPage = () =>{   

    const {isAuth} = useContext(AppContext); 
    const nav = useNavigate();
    const [sendError, setSendError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [district, setDistrict] = useState("");
    const [title, setTitle] = useState("");
    const [pass, setPass] = useState("");
    const [repass, setRePass] = useState("");
    const [state, setState] = useState("");
    const [news, setNews] = useState(true);

    useEffect(() => {
        if(isAuth === true){
            nav("/");
        }
    }, [isAuth, nav]);

     const handleSubmit = async (e) =>{
        e.preventDefault();
        const registered = {
            fname: fname,
            lname: lname,
            district: district,
            title: title,
            email: email,
            pass: pass,
            repass: repass,
            news: news,
            state: state
        }

        await axios.post("/api/signup", registered)
        .then(res =>{
            if(res.data === "User has been added. Verification email was sent"){
                setFname("");
                setLname("");
                setEmail("");
                setDistrict("");
                setTitle("");
                setPass("");
                setRePass("");
                setNews("");
                setState("");
                setShowSuccess(true);
            }
            else{
                setSendError(true);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

     return(      
        <div>
            <Navbar/>
                <div className = "contactBackground">    
                
                    <div className = "contactContent">

                        {sendError === true && <Alert className = "Alert" variant="danger" onClose={e => setSendError(false)} dismissible>
                            Error adding user.
                        </Alert>}

                        {showSuccess === true && <Alert className = "Alert" variant="success" onClose={e => setShowSuccess(false)} dismissible>
                            User added successfully. Verification email sent.
                        </Alert>}

                        <h1 className = "contactTitle">Register an Account</h1>

                        <form onSubmit={handleSubmit}>
                            <div className = "field1">
                                
                                <input placeholder="First Name" value = {fname} required onChange={e => setFname(e.target.value)}/>        
                                <input placeholder="Last Name" value = {lname} required onChange={e => setLname(e.target.value)}/>
                                <input placeholder="Title" value = {title} required onChange={e => setTitle(e.target.value)}/>
                                <input placeholder="District" value = {district} required onChange={e => setDistrict(e.target.value)}/>        
                                <select onChange={e => setState(e.target.value)}>
                                    <option value="" disabled selected>Select your option</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select> 
                                <input type="email" placeholder="E-mail Address" value = {email} required onChange={e => setEmail(e.target.value)}/>
                                <input type="password" placeholder="Password" value = {pass} required onChange={e => setPass(e.target.value)}/>
                                <input type="password" placeholder="Confirm Password" value = {repass} required onChange={e => setRePass(e.target.value)}/>

                                <label className='checkboxNews'>
                                    <input type="checkbox" className='checkbox' checked = {news} onChange = {e => setNews(!news)}/>
                                    <p className='acceptStatement'>Yes! I want to enroll in RSFA's newsletter via email and accept the Privacy Policy</p>
                                </label>    
                            </div>

                            <button type = "submit" className = "submitBtn">Create Account</button>
                        </form>
                    </div>            
                </div>
            <Footer/>
        </div>
    );
};
export default SignUpPage;